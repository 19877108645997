/*
File generated by js-routes 1.4.9
Based on Rails 4.2.11 routes of Path::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accounts_settings => /accounts/settings(.:format)
  // function(options)
  accountsSettingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// accounts_settings_contact_export_attributes => /accounts/settings/contact_export_attributes(.:format)
  // function(options)
  accountsSettingsContactExportAttributesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"contact_export_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accounts_settings_contact_self_registration_attributes => /accounts/settings/contact_self_registration_attributes(.:format)
  // function(options)
  accountsSettingsContactSelfRegistrationAttributesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"contact_self_registration_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// addresses_autosuggest_index => /addresses/autosuggest(.:format)
  // function(options)
  addressesAutosuggestIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"autosuggest",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// addresses_search_index => /addresses/search(.:format)
  // function(options)
  addressesSearchIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// agreement => /agreements/:id(.:format)
  // function(id, options)
  agreementPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// agreement_activities => /agreements/:agreement_id/activities(.:format)
  // function(agreement_id, options)
  agreementActivitiesPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// agreement_linkings_equipments => /agreements/:agreement_id/linkings/equipments(.:format)
  // function(agreement_id, options)
  agreementLinkingsEquipmentsPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"linkings",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// agreement_linkings_maintenance_entities => /agreements/:agreement_id/linkings/maintenance_entities(.:format)
  // function(agreement_id, options)
  agreementLinkingsMaintenanceEntitiesPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"linkings",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// agreement_linkings_select_all_equipments => /agreements/:agreement_id/linkings/select_all/equipments(.:format)
  // function(agreement_id, options)
  agreementLinkingsSelectAllEquipmentsPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"linkings",false],[2,[7,"/",false],[2,[6,"select_all",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// agreement_linkings_select_all_maintenance_entities => /agreements/:agreement_id/linkings/select_all/maintenance_entities(.:format)
  // function(agreement_id, options)
  agreementLinkingsSelectAllMaintenanceEntitiesPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"linkings",false],[2,[7,"/",false],[2,[6,"select_all",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// agreement_linkings_selected_equipments => /agreements/:agreement_id/linkings/selected/equipments(.:format)
  // function(agreement_id, options)
  agreementLinkingsSelectedEquipmentsPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"linkings",false],[2,[7,"/",false],[2,[6,"selected",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// agreement_linkings_selected_maintenance_entities => /agreements/:agreement_id/linkings/selected/maintenance_entities(.:format)
  // function(agreement_id, options)
  agreementLinkingsSelectedMaintenanceEntitiesPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"linkings",false],[2,[7,"/",false],[2,[6,"selected",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// agreement_previews => /agreements/:agreement_id/previews(.:format)
  // function(agreement_id, options)
  agreementPreviewsPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// agreement_previews_parameters => /agreements/:agreement_id/previews/parameters(.:format)
  // function(agreement_id, options)
  agreementPreviewsParametersPath: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[2,[7,"/",false],[2,[6,"previews",false],[2,[7,"/",false],[2,[6,"parameters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// agreements => /agreements(.:format)
  // function(options)
  agreementsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// agreements_exports => /agreements/exports(.:format)
  // function(options)
  agreementsExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attachments_list_issue => /issues/:id/attachments_list(.:format)
  // function(id, options)
  attachmentsListIssuePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"attachments_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// calendar_days_day_issues => /issues/calendar/days/day_issues(.:format)
  // function(options)
  calendarDaysDayIssuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"day_issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar_days_employees => /issues/calendar/days/employees(.:format)
  // function(options)
  calendarDaysEmployeesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar_days_issue => /issues/calendar/days/issues/:id(.:format)
  // function(id, options)
  calendarDaysIssuePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// calendar_days_issues => /issues/calendar/days/issues(.:format)
  // function(options)
  calendarDaysIssuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar_days_repeatable_day_issues => /issues/calendar/days/repeatable_day_issues(.:format)
  // function(options)
  calendarDaysRepeatableDayIssuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"repeatable_day_issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar_days_settings => /issues/calendar/days/settings(.:format)
  // function(options)
  calendarDaysSettingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// check_settings_integrations_wialon_connection => /settings/integrations/wialon/connections/:id/check(.:format)
  // function(id, options)
  checkSettingsIntegrationsWialonConnectionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"wialon",false],[2,[7,"/",false],[2,[6,"connections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// collections_activities_authors => /collections/activities/authors(.:format)
  // function(options)
  collectionsActivitiesAuthorsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"authors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_activities_events => /collections/activities/events(.:format)
  // function(options)
  collectionsActivitiesEventsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_assignees => /collections/assignees(.:format)
  // function(options)
  collectionsAssigneesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"assignees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_companies => /collections/companies(.:format)
  // function(options)
  collectionsCompaniesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_company_categories => /collections/company_categories(.:format)
  // function(options)
  collectionsCompanyCategoriesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"company_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_contact_observers => /collections/contact_observers(.:format)
  // function(options)
  collectionsContactObserversPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"contact_observers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_contacts => /collections/contacts(.:format)
  // function(options)
  collectionsContactsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_contacts_with_external_observers => /collections/contacts_with_external_observers(.:format)
  // function(options)
  collectionsContactsWithExternalObserversPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"contacts_with_external_observers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_employees => /collections/employees(.:format)
  // function(options)
  collectionsEmployeesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_employees_observers_grouped_by_employee_groups => /collections/employees/observers_grouped_by_employee_groups(.:format)
  // function(options)
  collectionsEmployeesObserversGroupedByEmployeeGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"observers_grouped_by_employee_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_equipment_kinds => /collections/equipment_kinds(.:format)
  // function(options)
  collectionsEquipmentKindsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"equipment_kinds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_equipments_activity_authors => /collections/equipments/activity_authors(.:format)
  // function(options)
  collectionsEquipmentsActivityAuthorsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"activity_authors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_grouped_employees => /collections/grouped_employees(.:format)
  // function(options)
  collectionsGroupedEmployeesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"grouped_employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_groups => /collections/groups(.:format)
  // function(options)
  collectionsGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_issue_types => /collections/issue_types(.:format)
  // function(options)
  collectionsIssueTypesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issue_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_issues_check_lists_templates_group_ancestry_pathes => /collections/issues/check_lists/templates/groups/:group_id/ancestry_pathes(.:format)
  // function(group_id, options)
  collectionsIssuesCheckListsTemplatesGroupAncestryPathesPath: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"ancestry_pathes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// collections_issues_check_lists_templates_groups => /collections/issues/check_lists/templates/groups(.:format)
  // function(options)
  collectionsIssuesCheckListsTemplatesGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// collections_issues_create_forms_agreements => /collections/issues/create_forms/agreements(.:format)
  // function(options)
  collectionsIssuesCreateFormsAgreementsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"agreements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// collections_issues_create_forms_clients => /collections/issues/create_forms/clients(.:format)
  // function(options)
  collectionsIssuesCreateFormsClientsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// collections_issues_create_forms_equipments => /collections/issues/create_forms/equipments(.:format)
  // function(options)
  collectionsIssuesCreateFormsEquipmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// collections_issues_create_forms_maintenance_entities => /collections/issues/create_forms/maintenance_entities(.:format)
  // function(options)
  collectionsIssuesCreateFormsMaintenanceEntitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// collections_issues_equipments => /collections/issues/equipments(.:format)
  // function(options)
  collectionsIssuesEquipmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_issues_grouped_work_types => /collections/issues/grouped_work_types(.:format)
  // function(options)
  collectionsIssuesGroupedWorkTypesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"grouped_work_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_issues_parameters => /collections/issues/parameters(.:format)
  // function(options)
  collectionsIssuesParametersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"parameters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_issues_priorities => /collections/issues/priorities(.:format)
  // function(options)
  collectionsIssuesPrioritiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"priorities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_issues_work_type_groups => /collections/issues/work_type_groups(.:format)
  // function(options)
  collectionsIssuesWorkTypeGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_type_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_maintenance_entities => /collections/maintenance_entities(.:format)
  // function(options)
  collectionsMaintenanceEntitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_material_assets_documents_equipments => /collections/material_assets/documents/equipments(.:format)
  // function(options)
  collectionsMaterialAssetsDocumentsEquipmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// collections_nomenclature_group_ancestry_pathes => /collections/nomenclature/groups/:group_id/ancestry_pathes(.:format)
  // function(group_id, options)
  collectionsNomenclatureGroupAncestryPathesPath: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"ancestry_pathes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// collections_nomenclature_grouped_items => /collections/nomenclature/grouped_items(.:format)
  // function(options)
  collectionsNomenclatureGroupedItemsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"grouped_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_nomenclature_grouped_services => /collections/nomenclature/grouped_services(.:format)
  // function(options)
  collectionsNomenclatureGroupedServicesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"grouped_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_nomenclature_groups => /collections/nomenclature/groups(.:format)
  // function(options)
  collectionsNomenclatureGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_nomenclature_item_types => /collections/nomenclature/item_types(.:format)
  // function(options)
  collectionsNomenclatureItemTypesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"item_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collections_observers => /collections/observers(.:format)
  // function(options)
  collectionsObserversPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"observers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_permitted_issue_types => /collections/permitted_issue_types(.:format)
  // function(options)
  collectionsPermittedIssueTypesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"permitted_issue_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_priorities => /collections/priorities(.:format)
  // function(options)
  collectionsPrioritiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"priorities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_sla_schedules => /collections/sla_schedules(.:format)
  // function(options)
  collectionsSlaSchedulesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"sla_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections_warehouses_plain_warehouses => /collections/warehouses/plain_warehouses(.:format)
  // function(options)
  collectionsWarehousesPlainWarehousesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"plain_warehouses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// companies => /companies(.:format)
  // function(options)
  companiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// companies_exports => /companies/exports(.:format)
  // function(options)
  companiesExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company => /companies/:id(.:format)
  // function(id, options)
  companyPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_activities => /companies/:company_id/activities(.:format)
  // function(company_id, options)
  companyActivitiesPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_agreements_exports => /companies/:company_id/agreements/exports(.:format)
  // function(company_id, options)
  companyAgreementsExportsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_agreements_tables => /companies/:company_id/agreements/tables(.:format)
  // function(company_id, options)
  companyAgreementsTablesPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_contacts_exports => /companies/:company_id/contacts/exports(.:format)
  // function(company_id, options)
  companyContactsExportsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_contacts_tables => /companies/:company_id/contacts/tables(.:format)
  // function(company_id, options)
  companyContactsTablesPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_equipments_exports => /companies/:company_id/equipments/exports(.:format)
  // function(company_id, options)
  companyEquipmentsExportsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_equipments_tables => /companies/:company_id/equipments/tables(.:format)
  // function(company_id, options)
  companyEquipmentsTablesPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_maintenance_entities => /companies/:company_id/maintenance_entities(.:format)
  // function(company_id, options)
  companyMaintenanceEntitiesPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_previews => /companies/:company_id/previews(.:format)
  // function(company_id, options)
  companyPreviewsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact => /contacts/:id(.:format)
  // function(id, options)
  contactPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_previews => /contacts/:contact_id/previews(.:format)
  // function(contact_id, options)
  contactPreviewsPath: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contacts => /contacts(.:format)
  // function(options)
  contactsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contacts_exports => /contacts/exports(.:format)
  // function(options)
  contactsExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_company_external_observers => /companies/:company_id/external_observers/edit(.:format)
  // function(company_id, options)
  editCompanyExternalObserversPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"external_observers",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_entity_workspaces_tables_columns => /entity_workspaces/tables/columns/edit(.:format)
  // function(options)
  editEntityWorkspacesTablesColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_issue_nested_bindings => /issues/:issue_id/nested_bindings/edit(.:format)
  // function(issue_id, options)
  editIssueNestedBindingsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"nested_bindings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_issues_view_types_tables_columns => /issues/view_types/tables/columns/edit(.:format)
  // function(options)
  editIssuesViewTypesTablesColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_material_assets_remainders_tables_columns => /material_assets/remainders/tables/columns/edit(.:format)
  // function(options)
  editMaterialAssetsRemaindersTablesColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"remainders",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_sidebar_columns => /issues/sidebar/columns/edit(.:format)
  // function(options)
  editSidebarColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"sidebar",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee => /employees/:id(.:format)
  // function(id, options)
  employeePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employees_maps_popups => /employees/maps/popups(.:format)
  // function(options)
  employeesMapsPopupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"popups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// entity_workspace => /entity_workspaces/:id(.:format)
  // function(id, options)
  entityWorkspacePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// entity_workspaces => /entity_workspaces(.:format)
  // function(options)
  entityWorkspacesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// entity_workspaces_count => /entity_workspaces/counts/:id(.:format)
  // function(id, options)
  entityWorkspacesCountPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"counts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// entity_workspaces_counts => /entity_workspaces/counts(.:format)
  // function(options)
  entityWorkspacesCountsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"counts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// entity_workspaces_fast_filter => /entity_workspaces/fast_filters/:id(.:format)
  // function(id, options)
  entityWorkspacesFastFilterPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"fast_filters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// entity_workspaces_fast_filters => /entity_workspaces/fast_filters(.:format)
  // function(options)
  entityWorkspacesFastFiltersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"fast_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// entity_workspaces_fast_filters_filters_copy => /entity_workspaces/fast_filters/filters_copy/:id(.:format)
  // function(id, options)
  entityWorkspacesFastFiltersFiltersCopyPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"fast_filters",false],[2,[7,"/",false],[2,[6,"filters_copy",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// entity_workspaces_fast_filters_select => /entity_workspaces/fast_filters/select/:id(.:format)
  // function(id, options)
  entityWorkspacesFastFiltersSelectPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"fast_filters",false],[2,[7,"/",false],[2,[6,"select",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// entity_workspaces_global_filters => /entity_workspaces/global_filters(.:format)
  // function(options)
  entityWorkspacesGlobalFiltersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"global_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// entity_workspaces_global_filters_resets => /entity_workspaces/global_filters/resets(.:format)
  // function(options)
  entityWorkspacesGlobalFiltersResetsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"global_filters",false],[2,[7,"/",false],[2,[6,"resets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// entity_workspaces_selection => /entity_workspaces/selection/:id(.:format)
  // function(id, options)
  entityWorkspacesSelectionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"selection",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// entity_workspaces_tables_columns => /entity_workspaces/tables/columns(.:format)
  // function(options)
  entityWorkspacesTablesColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// entity_workspaces_tables_columns_resizer => /entity_workspaces/tables/columns_resizer/:id(.:format)
  // function(id, options)
  entityWorkspacesTablesColumnsResizerPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns_resizer",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// equipment => /equipments/:id(.:format)
  // function(id, options)
  equipmentPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// equipment_activities => /equipments/:equipment_id/activities(.:format)
  // function(equipment_id, options)
  equipmentActivitiesPath: Utils.route([["equipment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"equipment_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// equipment_inheritance => /equipments/:equipment_id/inheritance(.:format)
  // function(equipment_id, options)
  equipmentInheritancePath: Utils.route([["equipment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"equipment_id",false],[2,[7,"/",false],[2,[6,"inheritance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// equipment_nested_equipments => /equipments/:equipment_id/nested_equipments(.:format)
  // function(equipment_id, options)
  equipmentNestedEquipmentsPath: Utils.route([["equipment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"equipment_id",false],[2,[7,"/",false],[2,[6,"nested_equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// equipment_nested_equipments_tables => /equipments/:equipment_id/nested_equipments/tables(.:format)
  // function(equipment_id, options)
  equipmentNestedEquipmentsTablesPath: Utils.route([["equipment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"equipment_id",false],[2,[7,"/",false],[2,[6,"nested_equipments",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// equipment_previews => /equipments/:equipment_id/previews(.:format)
  // function(equipment_id, options)
  equipmentPreviewsPath: Utils.route([["equipment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"equipment_id",false],[2,[7,"/",false],[2,[6,"previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// equipment_previews_parameters => /equipments/:equipment_id/previews/parameters(.:format)
  // function(equipment_id, options)
  equipmentPreviewsParametersPath: Utils.route([["equipment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[3,"equipment_id",false],[2,[7,"/",false],[2,[6,"previews",false],[2,[7,"/",false],[2,[6,"parameters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// equipments => /equipments(.:format)
  // function(options)
  equipmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// equipments_exports => /equipments/exports(.:format)
  // function(options)
  equipmentsExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_issues => /events/issues(.:format)
  // function(options)
  eventsIssuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_issues_event_items => /events/issues/event_items(.:format)
  // function(options)
  eventsIssuesEventItemsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"event_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// events_issues_unread_event => /events/issues/unread_events/:id(.:format)
  // function(id, options)
  eventsIssuesUnreadEventPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"unread_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_agreements_exports => /agreements/exports/export(.:format)
  // function(options)
  exportAgreementsExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_companies_exports => /companies/exports/export(.:format)
  // function(options)
  exportCompaniesExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_company_agreements_exports => /companies/:company_id/agreements/exports/export(.:format)
  // function(company_id, options)
  exportCompanyAgreementsExportsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_company_contacts_exports => /companies/:company_id/contacts/exports/export(.:format)
  // function(company_id, options)
  exportCompanyContactsExportsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_company_equipments_exports => /companies/:company_id/equipments/exports/export(.:format)
  // function(company_id, options)
  exportCompanyEquipmentsExportsPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_contacts_exports => /contacts/exports/export(.:format)
  // function(options)
  exportContactsExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_equipments_exports => /equipments/exports/export(.:format)
  // function(options)
  exportEquipmentsExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_issue_nested_issues_exports => /issues/:issue_id/nested_issues/exports/export(.:format)
  // function(issue_id, options)
  exportIssueNestedIssuesExportsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"nested_issues",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_issues_view_types_exports => /issues/view_types/exports/export(.:format)
  // function(options)
  exportIssuesViewTypesExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_maintenance_entities_exports => /maintenance_entities/exports/export(.:format)
  // function(options)
  exportMaintenanceEntitiesExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_maintenance_entity_contacts_exports => /maintenance_entities/:maintenance_entity_id/contacts/exports/export(.:format)
  // function(maintenance_entity_id, options)
  exportMaintenanceEntityContactsExportsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_maintenance_entity_equipments_exports => /maintenance_entities/:maintenance_entity_id/equipments/exports/export(.:format)
  // function(maintenance_entity_id, options)
  exportMaintenanceEntityEquipmentsExportsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_maintenance_entity_issues_exports => /maintenance_entities/:maintenance_entity_id/issues/exports/export(.:format)
  // function(maintenance_entity_id, options)
  exportMaintenanceEntityIssuesExportsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_settings_nomenclature_price_list_services_exports => /settings/nomenclature/price_lists/:price_list_id/services/exports/export(.:format)
  // function(price_list_id, options)
  exportSettingsNomenclaturePriceListServicesExportsPath: Utils.route([["price_list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// global_search_issues_table => /global_search/issues/tables/:id(.:format)
  // function(id, options)
  globalSearchIssuesTablePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"global_search",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// global_search_issues_tables => /global_search/issues/tables(.:format)
  // function(options)
  globalSearchIssuesTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"global_search",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// header_preloaded_info => /header_preloaded_info(.:format)
  // function(options)
  headerPreloadedInfoPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"header_preloaded_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// issue => /issues/:id(.:format)
  // function(id, options)
  issuePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// issue_addresses => /issues/:issue_id/addresses(.:format)
  // function(issue_id, options)
  issueAddressesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_assignees => /issues/:issue_id/assignees(.:format)
  // function(issue_id, options)
  issueAssigneesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"assignees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_calendar_days_coexecutors => /issues/:issue_id/calendar/days/coexecutors(.:format)
  // function(issue_id, options)
  issueCalendarDaysCoexecutorsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"coexecutors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_calendar_days_execution_times => /issues/:issue_id/calendar/days/execution_times(.:format)
  // function(issue_id, options)
  issueCalendarDaysExecutionTimesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"days",false],[2,[7,"/",false],[2,[6,"execution_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_check_lists_item => /issues/:issue_id/check_lists/items/:id(.:format)
  // function(issue_id, id, options)
  issueCheckListsItemPath: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_check_lists_items => /issues/:issue_id/check_lists/items(.:format)
  // function(issue_id, options)
  issueCheckListsItemsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issue_check_lists_items_attachment_previews => /issues/:issue_id/check_lists/items/attachment_previews(.:format)
  // function(issue_id, options)
  issueCheckListsItemsAttachmentPreviewsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"attachment_previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_check_lists_items_parameter => /issues/:issue_id/check_lists/items/parameters/:id(.:format)
  // function(issue_id, id, options)
  issueCheckListsItemsParameterPath: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"parameters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// issue_check_lists_items_required_status => /issues/:issue_id/check_lists/items/required_statuses/:id(.:format)
  // function(issue_id, id, options)
  issueCheckListsItemsRequiredStatusPath: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"required_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// issue_check_lists_items_required_statuses => /issues/:issue_id/check_lists/items/required_statuses(.:format)
  // function(issue_id, options)
  issueCheckListsItemsRequiredStatusesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"required_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_descriptions => /issues/:issue_id/descriptions(.:format)
  // function(issue_id, options)
  issueDescriptionsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"descriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_equipments => /issues/:issue_id/equipments(.:format)
  // function(issue_id, options)
  issueEquipmentsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_execution_times => /issues/:issue_id/execution_times(.:format)
  // function(issue_id, options)
  issueExecutionTimesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"execution_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_nested_issues_exports => /issues/:issue_id/nested_issues/exports(.:format)
  // function(issue_id, options)
  issueNestedIssuesExportsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"nested_issues",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issue_nested_issues_table => /issues/:issue_id/nested_issues/tables/:id(.:format)
  // function(issue_id, id, options)
  issueNestedIssuesTablePath: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"nested_issues",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_nested_issues_tables => /issues/:issue_id/nested_issues/tables(.:format)
  // function(issue_id, options)
  issueNestedIssuesTablesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"nested_issues",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issue_partnerships_client => /issues/:issue_id/partnerships/clients/:id(.:format)
  // function(issue_id, id, options)
  issuePartnershipsClientPath: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"partnerships",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_previews_parameters => /issues/:issue_id/previews/parameters(.:format)
  // function(issue_id, options)
  issuePreviewsParametersPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"previews",false],[2,[7,"/",false],[2,[6,"parameters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issue_priorities => /issues/:issue_id/priorities(.:format)
  // function(issue_id, options)
  issuePrioritiesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"priorities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_source_email_informations => /issues/:issue_id/source_email_informations(.:format)
  // function(issue_id, options)
  issueSourceEmailInformationsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"source_email_informations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue_view_types_tables_assignees => /issues/:issue_id/view_types/tables/assignees(.:format)
  // function(issue_id, options)
  issueViewTypesTablesAssigneesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"assignees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_companies => /issues/:issue_id/view_types/tables/companies(.:format)
  // function(issue_id, options)
  issueViewTypesTablesCompaniesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_contacts => /issues/:issue_id/view_types/tables/contacts(.:format)
  // function(issue_id, options)
  issueViewTypesTablesContactsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_deadlines => /issues/:issue_id/view_types/tables/deadlines(.:format)
  // function(issue_id, options)
  issueViewTypesTablesDeadlinesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"deadlines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_maintenance_entities => /issues/:issue_id/view_types/tables/maintenance_entities(.:format)
  // function(issue_id, options)
  issueViewTypesTablesMaintenanceEntitiesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_nested_issues => /issues/:issue_id/view_types/tables/nested_issues(.:format)
  // function(issue_id, options)
  issueViewTypesTablesNestedIssuesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"nested_issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_observers => /issues/:issue_id/view_types/tables/observers(.:format)
  // function(issue_id, options)
  issueViewTypesTablesObserversPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"observers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_priorities => /issues/:issue_id/view_types/tables/priorities(.:format)
  // function(issue_id, options)
  issueViewTypesTablesPrioritiesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"priorities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_start_execution_times => /issues/:issue_id/view_types/tables/start_execution_times(.:format)
  // function(issue_id, options)
  issueViewTypesTablesStartExecutionTimesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"start_execution_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_view_types_tables_work_types => /issues/:issue_id/view_types/tables/work_types(.:format)
  // function(issue_id, options)
  issueViewTypesTablesWorkTypesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"work_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issue_work_types => /issues/:issue_id/work_types(.:format)
  // function(issue_id, options)
  issueWorkTypesPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"work_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues => /issues(.:format)
  // function(options)
  issuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// issues_check_lists_templates => /issues/check_lists/templates(.:format)
  // function(options)
  issuesCheckListsTemplatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_check_lists_templates_items_required_statuses => /issues/check_lists/templates/items/required_statuses(.:format)
  // function(options)
  issuesCheckListsTemplatesItemsRequiredStatusesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"required_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issues_create_forms_new => /issues/create_forms/new(.:format)
  // function(options)
  issuesCreateFormsNewPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_create_forms_parameters_index => /issues/create_forms/parameters/index(.:format)
  // function(options)
  issuesCreateFormsParametersIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"parameters",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_create_forms_relation_fields => /issues/create_forms/relation_fields(.:format)
  // function(options)
  issuesCreateFormsRelationFieldsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"relation_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_create_forms_update => /issues/create_forms/update(.:format)
  // function(options)
  issuesCreateFormsUpdatePath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"create_forms",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_assignee => /issues/maps/assignees/:id(.:format)
  // function(id, options)
  issuesMapsAssigneePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"assignees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_maps_employees => /issues/maps/employees(.:format)
  // function(options)
  issuesMapsEmployeesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_employees_with_coordinates => /issues/maps/employees_with_coordinates(.:format)
  // function(options)
  issuesMapsEmployeesWithCoordinatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"employees_with_coordinates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_issues => /issues/maps/issues(.:format)
  // function(options)
  issuesMapsIssuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_issues_with_coordinates => /issues/maps/issues_with_coordinates(.:format)
  // function(options)
  issuesMapsIssuesWithCoordinatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"issues_with_coordinates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_issues_without_coordinates => /issues/maps/issues_without_coordinates(.:format)
  // function(options)
  issuesMapsIssuesWithoutCoordinatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"issues_without_coordinates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_repeat_issues => /issues/maps/repeat_issues(.:format)
  // function(options)
  issuesMapsRepeatIssuesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"repeat_issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_maps_view_settings => /issues/maps/view_settings(.:format)
  // function(options)
  issuesMapsViewSettingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"maps",false],[2,[7,"/",false],[2,[6,"view_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_mass_creations_replication_lists_companies => /issues/mass_creations/replication_lists/companies(.:format)
  // function(options)
  issuesMassCreationsReplicationListsCompaniesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"mass_creations",false],[2,[7,"/",false],[2,[6,"replication_lists",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_mass_creations_replication_lists_equipments => /issues/mass_creations/replication_lists/equipments(.:format)
  // function(options)
  issuesMassCreationsReplicationListsEquipmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"mass_creations",false],[2,[7,"/",false],[2,[6,"replication_lists",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_mass_creations_replication_lists_maintenance_entities => /issues/mass_creations/replication_lists/maintenance_entities(.:format)
  // function(options)
  issuesMassCreationsReplicationListsMaintenanceEntitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"mass_creations",false],[2,[7,"/",false],[2,[6,"replication_lists",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_mass_creations_replication_lists_select_all_companies => /issues/mass_creations/replication_lists/select_all/companies(.:format)
  // function(options)
  issuesMassCreationsReplicationListsSelectAllCompaniesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"mass_creations",false],[2,[7,"/",false],[2,[6,"replication_lists",false],[2,[7,"/",false],[2,[6,"select_all",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issues_mass_creations_replication_lists_select_all_equipments => /issues/mass_creations/replication_lists/select_all/equipments(.:format)
  // function(options)
  issuesMassCreationsReplicationListsSelectAllEquipmentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"mass_creations",false],[2,[7,"/",false],[2,[6,"replication_lists",false],[2,[7,"/",false],[2,[6,"select_all",false],[2,[7,"/",false],[2,[6,"equipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issues_mass_creations_replication_lists_select_all_maintenance_entities => /issues/mass_creations/replication_lists/select_all/maintenance_entities(.:format)
  // function(options)
  issuesMassCreationsReplicationListsSelectAllMaintenanceEntitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"mass_creations",false],[2,[7,"/",false],[2,[6,"replication_lists",false],[2,[7,"/",false],[2,[6,"select_all",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// issues_status_forms_check_lists_required_items => /issues/status_forms/check_lists/required_items(.:format)
  // function(options)
  issuesStatusFormsCheckListsRequiredItemsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"status_forms",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"required_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_view_types_calendars_day_calendars => /issues/view_types/calendars/day_calendars(.:format)
  // function(options)
  issuesViewTypesCalendarsDayCalendarsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"day_calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_view_types_calendars_month_calendars => /issues/view_types/calendars/month_calendars(.:format)
  // function(options)
  issuesViewTypesCalendarsMonthCalendarsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"month_calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_view_types_calendars_week_calendars => /issues/view_types/calendars/week_calendars(.:format)
  // function(options)
  issuesViewTypesCalendarsWeekCalendarsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"week_calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_view_types_exports => /issues/view_types/exports(.:format)
  // function(options)
  issuesViewTypesExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_view_types_maps => /issues/view_types/maps(.:format)
  // function(options)
  issuesViewTypesMapsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"maps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_view_types_table => /issues/view_types/tables/:id(.:format)
  // function(id, options)
  issuesViewTypesTablePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// issues_view_types_tables => /issues/view_types/tables(.:format)
  // function(options)
  issuesViewTypesTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_view_types_tables_columns => /issues/view_types/tables/columns(.:format)
  // function(options)
  issuesViewTypesTablesColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"view_types",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entities => /maintenance_entities(.:format)
  // function(options)
  maintenanceEntitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// maintenance_entities_exports => /maintenance_entities/exports(.:format)
  // function(options)
  maintenanceEntitiesExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// maintenance_entity => /maintenance_entities/:id(.:format)
  // function(id, options)
  maintenanceEntityPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// maintenance_entity_activities => /maintenance_entities/:maintenance_entity_id/activities(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityActivitiesPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// maintenance_entity_contacts_exports => /maintenance_entities/:maintenance_entity_id/contacts/exports(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityContactsExportsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entity_contacts_tables => /maintenance_entities/:maintenance_entity_id/contacts/tables(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityContactsTablesPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entity_equipments_exports => /maintenance_entities/:maintenance_entity_id/equipments/exports(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityEquipmentsExportsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entity_equipments_tables => /maintenance_entities/:maintenance_entity_id/equipments/tables(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityEquipmentsTablesPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entity_issues_exports => /maintenance_entities/:maintenance_entity_id/issues/exports(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityIssuesExportsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entity_issues_table => /maintenance_entities/:maintenance_entity_id/issues/tables/:issue_id(.:format)
  // function(maintenance_entity_id, issue_id, options)
  maintenanceEntityIssuesTablePath: Utils.route([["maintenance_entity_id",true],["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[3,"issue_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// maintenance_entity_issues_tables => /maintenance_entities/:maintenance_entity_id/issues/tables(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityIssuesTablesPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// maintenance_entity_previews => /maintenance_entities/:maintenance_entity_id/previews(.:format)
  // function(maintenance_entity_id, options)
  maintenanceEntityPreviewsPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mass_update_entity_workspaces_global_filters => /entity_workspaces/global_filters/mass_update(.:format)
  // function(options)
  massUpdateEntityWorkspacesGlobalFiltersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity_workspaces",false],[2,[7,"/",false],[2,[6,"global_filters",false],[2,[7,"/",false],[2,[6,"mass_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// material_assets_document => /material_assets/documents/:id(.:format)
  // function(id, options)
  materialAssetsDocumentPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// material_assets_document_activities => /material_assets/documents/:document_id/activities(.:format)
  // function(document_id, options)
  materialAssetsDocumentActivitiesPath: Utils.route([["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// material_assets_document_equipment_item => /material_assets/documents/:document_id/equipment_items/:id(.:format)
  // function(document_id, id, options)
  materialAssetsDocumentEquipmentItemPath: Utils.route([["document_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"equipment_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// material_assets_document_equipment_items => /material_assets/documents/:document_id/equipment_items(.:format)
  // function(document_id, options)
  materialAssetsDocumentEquipmentItemsPath: Utils.route([["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"equipment_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// material_assets_document_item => /material_assets/documents/:document_id/items/:id(.:format)
  // function(document_id, id, options)
  materialAssetsDocumentItemPath: Utils.route([["document_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// material_assets_document_items => /material_assets/documents/:document_id/items(.:format)
  // function(document_id, options)
  materialAssetsDocumentItemsPath: Utils.route([["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// material_assets_document_items_tables => /material_assets/documents/:document_id/items_tables(.:format)
  // function(document_id, options)
  materialAssetsDocumentItemsTablesPath: Utils.route([["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"items_tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// material_assets_document_states => /material_assets/documents/:document_id/states(.:format)
  // function(document_id, options)
  materialAssetsDocumentStatesPath: Utils.route([["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// material_assets_documents => /material_assets/documents(.:format)
  // function(options)
  materialAssetsDocumentsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// material_assets_documents_items_related_form_attributes => /material_assets/documents/items/related_form_attributes(.:format)
  // function(options)
  materialAssetsDocumentsItemsRelatedFormAttributesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"related_form_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// material_assets_documents_tables => /material_assets/documents/tables(.:format)
  // function(options)
  materialAssetsDocumentsTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// material_assets_remainders_tables => /material_assets/remainders/tables(.:format)
  // function(options)
  materialAssetsRemaindersTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"remainders",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// material_assets_remainders_tables_columns => /material_assets/remainders/tables/columns(.:format)
  // function(options)
  materialAssetsRemaindersTablesColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"material_assets",false],[2,[7,"/",false],[2,[6,"remainders",false],[2,[7,"/",false],[2,[6,"tables",false],[2,[7,"/",false],[2,[6,"columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mobile_collections_issues_check_lists_templates => /mobile/collections/issues/check_lists/templates(.:format)
  // function(options)
  mobileCollectionsIssuesCheckListsTemplatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_agreement => /agreements/new(.:format)
  // function(options)
  newAgreementPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_company_contact => /companies/:company_id/contacts/new(.:format)
  // function(company_id, options)
  newCompanyContactPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_company_equipment => /companies/:company_id/equipments/new(.:format)
  // function(company_id, options)
  newCompanyEquipmentPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_company_maintenance_entity => /companies/:company_id/maintenance_entities/new(.:format)
  // function(company_id, options)
  newCompanyMaintenanceEntityPath: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_equipment => /equipments/new(.:format)
  // function(options)
  newEquipmentPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_issue_equipments => /issues/:issue_id/equipments/new(.:format)
  // function(issue_id, options)
  newIssueEquipmentsPath: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_maintenance_entity => /maintenance_entities/new(.:format)
  // function(options)
  newMaintenanceEntityPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_maintenance_entity_contact => /maintenance_entities/:maintenance_entity_id/contacts/new(.:format)
  // function(maintenance_entity_id, options)
  newMaintenanceEntityContactPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_maintenance_entity_equipment => /maintenance_entities/:maintenance_entity_id/equipments/new(.:format)
  // function(maintenance_entity_id, options)
  newMaintenanceEntityEquipmentPath: Utils.route([["maintenance_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[3,"maintenance_entity_id",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_settings_issues_parameter => /settings/issues/parameters/new(.:format)
  // function(options)
  newSettingsIssuesParameterPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"parameters",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_settings_issues_type => /settings/issues/types/new(.:format)
  // function(options)
  newSettingsIssuesTypePath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// normalizers_time_format => /normalizers/time_format(.:format)
  // function(options)
  normalizersTimeFormatPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"normalizers",false],[2,[7,"/",false],[2,[6,"time_format",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// preview_issue => /issues/:id/preview(.:format)
  // function(id, options)
  previewIssuePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_access_rights_additionals_permissions => /settings/access_rights/additionals/permissions(.:format)
  // function(options)
  settingsAccessRightsAdditionalsPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"additionals",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_agreements_permission => /settings/access_rights/agreements/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsAgreementsPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_agreements_permissions => /settings/access_rights/agreements/permissions(.:format)
  // function(options)
  settingsAccessRightsAgreementsPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"agreements",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_companies_permission => /settings/access_rights/companies/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsCompaniesPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_companies_permissions => /settings/access_rights/companies/permissions(.:format)
  // function(options)
  settingsAccessRightsCompaniesPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_contacts_permission => /settings/access_rights/contacts/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsContactsPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_contacts_permissions => /settings/access_rights/contacts/permissions(.:format)
  // function(options)
  settingsAccessRightsContactsPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_employee_role => /settings/access_rights/employee_roles/:id(.:format)
  // function(id, options)
  settingsAccessRightsEmployeeRolePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"employee_roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_employee_roles => /settings/access_rights/employee_roles(.:format)
  // function(options)
  settingsAccessRightsEmployeeRolesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"employee_roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_access_rights_employee_roles_positions => /settings/access_rights/employee_roles/positions(.:format)
  // function(options)
  settingsAccessRightsEmployeeRolesPositionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"employee_roles",false],[2,[7,"/",false],[2,[6,"positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_equipments_permission => /settings/access_rights/equipments/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsEquipmentsPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_equipments_permissions => /settings/access_rights/equipments/permissions(.:format)
  // function(options)
  settingsAccessRightsEquipmentsPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"equipments",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_issues_permission => /settings/access_rights/issues/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsIssuesPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_issues_permissions => /settings/access_rights/issues/permissions(.:format)
  // function(options)
  settingsAccessRightsIssuesPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_maintenance_entities_permission => /settings/access_rights/maintenance_entities/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsMaintenanceEntitiesPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_maintenance_entities_permissions => /settings/access_rights/maintenance_entities/permissions(.:format)
  // function(options)
  settingsAccessRightsMaintenanceEntitiesPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"maintenance_entities",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_permission_activation => /settings/access_rights/permission_activations/:id(.:format)
  // function(id, options)
  settingsAccessRightsPermissionActivationPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"permission_activations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_permission_info => /settings/access_rights/permission_info/:id(.:format)
  // function(id, options)
  settingsAccessRightsPermissionInfoPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"permission_info",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_access_rights_warehouses_permission => /settings/access_rights/warehouses/permissions/:id(.:format)
  // function(id, options)
  settingsAccessRightsWarehousesPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_access_rights_warehouses_permissions => /settings/access_rights/warehouses/permissions(.:format)
  // function(options)
  settingsAccessRightsWarehousesPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"access_rights",false],[2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_base_api_key => /settings/base_api_keys/:id(.:format)
  // function(id, options)
  settingsBaseApiKeyPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"base_api_keys",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_base_api_key_active => /settings/base_api_keys/:base_api_key_id/active(.:format)
  // function(base_api_key_id, options)
  settingsBaseApiKeyActivePath: Utils.route([["base_api_key_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"base_api_keys",false],[2,[7,"/",false],[2,[3,"base_api_key_id",false],[2,[7,"/",false],[2,[6,"active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_base_api_key_generate_token => /settings/base_api_keys/:base_api_key_id/generate_token(.:format)
  // function(base_api_key_id, options)
  settingsBaseApiKeyGenerateTokenPath: Utils.route([["base_api_key_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"base_api_keys",false],[2,[7,"/",false],[2,[3,"base_api_key_id",false],[2,[7,"/",false],[2,[6,"generate_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_base_api_keys => /settings/base_api_keys(.:format)
  // function(options)
  settingsBaseApiKeysPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"base_api_keys",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings_check_lists_template => /settings/check_lists/templates/:id(.:format)
  // function(id, options)
  settingsCheckListsTemplatePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_check_lists_template_copies => /settings/check_lists/templates/:template_id/copies(.:format)
  // function(template_id, options)
  settingsCheckListsTemplateCopiesPath: Utils.route([["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"copies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_check_lists_template_items => /settings/check_lists/templates/:template_id/items(.:format)
  // function(template_id, options)
  settingsCheckListsTemplateItemsPath: Utils.route([["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_check_lists_templates => /settings/check_lists/templates(.:format)
  // function(options)
  settingsCheckListsTemplatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_check_lists_templates_group => /settings/check_lists/templates/groups/:id(.:format)
  // function(id, options)
  settingsCheckListsTemplatesGroupPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_check_lists_templates_groups => /settings/check_lists/templates/groups(.:format)
  // function(options)
  settingsCheckListsTemplatesGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_integrations_wialon_connection => /settings/integrations/wialon/connections/:id(.:format)
  // function(id, options)
  settingsIntegrationsWialonConnectionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"wialon",false],[2,[7,"/",false],[2,[6,"connections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_integrations_wialon_connections => /settings/integrations/wialon/connections(.:format)
  // function(options)
  settingsIntegrationsWialonConnectionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"wialon",false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_integrations_wialon_parameters => /settings/integrations/wialon/parameters(.:format)
  // function(options)
  settingsIntegrationsWialonParametersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"wialon",false],[2,[7,"/",false],[2,[6,"parameters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_integrations_wialon_settings => /settings/integrations/wialon/settings(.:format)
  // function(options)
  settingsIntegrationsWialonSettingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"wialon",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_parameter => /settings/issues/parameters/:id(.:format)
  // function(id, options)
  settingsIssuesParameterPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"parameters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_parameters_positions => /settings/issues/parameters/positions(.:format)
  // function(options)
  settingsIssuesParametersPositionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"parameters",false],[2,[7,"/",false],[2,[6,"positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_parameters_tables => /settings/issues/parameters/tables(.:format)
  // function(options)
  settingsIssuesParametersTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"parameters",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_sla_rule => /settings/issues/sla/rules/:id(.:format)
  // function(id, options)
  settingsIssuesSlaRulePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"sla",false],[2,[7,"/",false],[2,[6,"rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_issues_sla_rules => /settings/issues/sla/rules(.:format)
  // function(options)
  settingsIssuesSlaRulesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"sla",false],[2,[7,"/",false],[2,[6,"rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_type => /settings/issues/types/:id(.:format)
  // function(id, options)
  settingsIssuesTypePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_types => /settings/issues/types(.:format)
  // function(options)
  settingsIssuesTypesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_issues_work_group => /settings/issues/work_groups/:id(.:format)
  // function(id, options)
  settingsIssuesWorkGroupPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_groups => /settings/issues/work_groups(.:format)
  // function(options)
  settingsIssuesWorkGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_issues_work_type_groups_ancestry_path => /settings/issues/work_type_groups/ancestry_paths/:id(.:format)
  // function(id, options)
  settingsIssuesWorkTypeGroupsAncestryPathPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_type_groups",false],[2,[7,"/",false],[2,[6,"ancestry_paths",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_issues_work_type_groups_check_uniqueness_index => /settings/issues/work_type_groups/check_uniqueness(.:format)
  // function(options)
  settingsIssuesWorkTypeGroupsCheckUniquenessIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_type_groups",false],[2,[7,"/",false],[2,[6,"check_uniqueness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_type_groups_positions => /settings/issues/work_type_groups/positions(.:format)
  // function(options)
  settingsIssuesWorkTypeGroupsPositionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_type_groups",false],[2,[7,"/",false],[2,[6,"positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_type_groups_visibilities => /settings/issues/work_type_groups/visibilities(.:format)
  // function(options)
  settingsIssuesWorkTypeGroupsVisibilitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_type_groups",false],[2,[7,"/",false],[2,[6,"visibilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_types_check_uniqueness_index => /settings/issues/work_types/check_uniqueness(.:format)
  // function(options)
  settingsIssuesWorkTypesCheckUniquenessIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_types",false],[2,[7,"/",false],[2,[6,"check_uniqueness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_types_positions => /settings/issues/work_types/positions(.:format)
  // function(options)
  settingsIssuesWorkTypesPositionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_types",false],[2,[7,"/",false],[2,[6,"positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_types_tables => /settings/issues/work_types/tables(.:format)
  // function(options)
  settingsIssuesWorkTypesTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_types",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_issues_work_types_visibilities => /settings/issues/work_types/visibilities(.:format)
  // function(options)
  settingsIssuesWorkTypesVisibilitiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"work_types",false],[2,[7,"/",false],[2,[6,"visibilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_group => /settings/nomenclature/groups/:id(.:format)
  // function(id, options)
  settingsNomenclatureGroupPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_groups => /settings/nomenclature/groups(.:format)
  // function(options)
  settingsNomenclatureGroupsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_nomenclature_groups_activity => /settings/nomenclature/groups/activities/:id(.:format)
  // function(id, options)
  settingsNomenclatureGroupsActivityPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_nomenclature_groups_check_uniqueness_index => /settings/nomenclature/groups/check_uniqueness(.:format)
  // function(options)
  settingsNomenclatureGroupsCheckUniquenessIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"check_uniqueness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_groups_tables => /settings/nomenclature/groups/tables(.:format)
  // function(options)
  settingsNomenclatureGroupsTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_item => /settings/nomenclature/items/:id(.:format)
  // function(id, options)
  settingsNomenclatureItemPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_items => /settings/nomenclature/items(.:format)
  // function(options)
  settingsNomenclatureItemsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_nomenclature_items_activity => /settings/nomenclature/items/activities/:id(.:format)
  // function(id, options)
  settingsNomenclatureItemsActivityPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_nomenclature_items_check_uniqueness_index => /settings/nomenclature/items/check_uniqueness(.:format)
  // function(options)
  settingsNomenclatureItemsCheckUniquenessIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"check_uniqueness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_items_description_preview => /settings/nomenclature/items/description_previews/:id(.:format)
  // function(id, options)
  settingsNomenclatureItemsDescriptionPreviewPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"description_previews",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_nomenclature_items_tables => /settings/nomenclature/items/tables(.:format)
  // function(options)
  settingsNomenclatureItemsTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_price_list => /settings/nomenclature/price_lists/:id(.:format)
  // function(id, options)
  settingsNomenclaturePriceListPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_price_list_groups_trees => /settings/nomenclature/price_lists/:price_list_id/groups_trees(.:format)
  // function(price_list_id, options)
  settingsNomenclaturePriceListGroupsTreesPath: Utils.route([["price_list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"groups_trees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_nomenclature_price_list_service => /settings/nomenclature/price_lists/:price_list_id/services/:id(.:format)
  // function(price_list_id, id, options)
  settingsNomenclaturePriceListServicePath: Utils.route([["price_list_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_nomenclature_price_list_services => /settings/nomenclature/price_lists/:price_list_id/services(.:format)
  // function(price_list_id, options)
  settingsNomenclaturePriceListServicesPath: Utils.route([["price_list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_nomenclature_price_list_services_description_preview => /settings/nomenclature/price_lists/:price_list_id/services/description_previews/:id(.:format)
  // function(price_list_id, id, options)
  settingsNomenclaturePriceListServicesDescriptionPreviewPath: Utils.route([["price_list_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"description_previews",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// settings_nomenclature_price_list_services_exports => /settings/nomenclature/price_lists/:price_list_id/services/exports(.:format)
  // function(price_list_id, options)
  settingsNomenclaturePriceListServicesExportsPath: Utils.route([["price_list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_nomenclature_price_list_services_tables => /settings/nomenclature/price_lists/:price_list_id/services/tables(.:format)
  // function(price_list_id, options)
  settingsNomenclaturePriceListServicesTablesPath: Utils.route([["price_list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_nomenclature_price_list_services_visibility => /settings/nomenclature/price_lists/:price_list_id/services/visibilities/:id(.:format)
  // function(price_list_id, id, options)
  settingsNomenclaturePriceListServicesVisibilityPath: Utils.route([["price_list_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[3,"price_list_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"visibilities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// settings_nomenclature_price_lists => /settings/nomenclature/price_lists(.:format)
  // function(options)
  settingsNomenclaturePriceListsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_nomenclature_price_lists_filters => /settings/nomenclature/price_lists/filters(.:format)
  // function(options)
  settingsNomenclaturePriceListsFiltersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[6,"filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_nomenclature_price_lists_services_related_item => /settings/nomenclature/price_lists/services/related_item(.:format)
  // function(options)
  settingsNomenclaturePriceListsServicesRelatedItemPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"related_item",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_nomenclature_price_lists_tables => /settings/nomenclature/price_lists/tables(.:format)
  // function(options)
  settingsNomenclaturePriceListsTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"nomenclature",false],[2,[7,"/",false],[2,[6,"price_lists",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_webhooks_item => /settings/webhooks/items/:id(.:format)
  // function(id, options)
  settingsWebhooksItemPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_webhooks_items => /settings/webhooks/items(.:format)
  // function(options)
  settingsWebhooksItemsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidebar_columns => /issues/sidebar/columns(.:format)
  // function(options)
  sidebarColumnsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"sidebar",false],[2,[7,"/",false],[2,[6,"columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// statuses_issue => /issues/:id/statuses(.:format)
  // function(id, options)
  statusesIssuePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// telephony_calls => /telephony/calls(.:format)
  // function(options)
  telephonyCallsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"telephony",false],[2,[7,"/",false],[2,[6,"calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// telephony_calls_answers => /telephony/calls/answers(.:format)
  // function(options)
  telephonyCallsAnswersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"telephony",false],[2,[7,"/",false],[2,[6,"calls",false],[2,[7,"/",false],[2,[6,"answers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// toggle_activation_settings_integrations_wialon_connection => /settings/integrations/wialon/connections/:id/toggle_activation(.:format)
  // function(id, options)
  toggleActivationSettingsIntegrationsWialonConnectionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"wialon",false],[2,[7,"/",false],[2,[6,"connections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_activation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// toggle_checked_issue_check_lists_item => /issues/:issue_id/check_lists/items/:id/toggle_checked(.:format)
  // function(issue_id, id, options)
  toggleCheckedIssueCheckListsItemPath: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"check_lists",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_checked",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// warehouse => /warehouses/:id(.:format)
  // function(id, options)
  warehousePath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// warehouse_activations => /warehouses/:warehouse_id/activations(.:format)
  // function(warehouse_id, options)
  warehouseActivationsPath: Utils.route([["warehouse_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"activations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// warehouse_documents_tables => /warehouses/:warehouse_id/documents/tables(.:format)
  // function(warehouse_id, options)
  warehouseDocumentsTablesPath: Utils.route([["warehouse_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// warehouse_remainders_tables => /warehouses/:warehouse_id/remainders/tables(.:format)
  // function(warehouse_id, options)
  warehouseRemaindersTablesPath: Utils.route([["warehouse_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"remainders",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// warehouses => /warehouses(.:format)
  // function(options)
  warehousesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// warehouses_tables => /warehouses/tables(.:format)
  // function(options)
  warehousesTablesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);
